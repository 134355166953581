import React from "react";
import './QuienesSomos.css';
import quienessomosbg from '../../assets/images/quienes-somos-bg.svg';
import cardbg from '../../assets/images/card-bg.jpg';
import doc from '../../assets/images/doc1.jpeg';
import doc2 from '../../assets/images/doc2.jpeg';

function QuienesSomos(){
    let stylePersonal= {
        backgroundImage: `url(${cardbg})`,
    }

    return (
        <section  
            id="quienes-somos" 
            className="quienes-somos container"
            style={{
                backgroundImage: `url(${quienessomosbg})`,
            }}
        >
            <div className="texto-nosotros">
                <h2>Quienes somos</h2>
                <p>Somos una empresa de salud privada, con conciencia social acompa&ntilde;ando al sector comercial e institucional en la b&uacute;squeda de la mejora y continua calidad a trav&eacute;s de un servicio personalizado de alta calidad y confiabilidad. Contamos con un Staff de profesionales especializados en actividades de salud, adem&aacute;s de equipos m&eacute;dicos autom&aacute;ticos y semiautom&aacute;ticos que sirven de importante herramienta para garantizar un adecuado diagn&oacute;stico.</p>
            </div>


            <div className="cards" >
                <div className="personal" style={stylePersonal}>
                    <img src={doc}
                        alt="personal uno"/>
                    <div className="personal-content">
                        <h3>Gabriel Zevallos M.</h3>
                        <p>Cofundador, Qu&iacute;mico y Administrador</p>
                    </div>
                </div>
                <div className="personal" style={stylePersonal}>
                    <img src={doc2}
                        alt="personal dos" />
                    <div className="personal-content">
                        <h3>Jacqueline Escalante</h3>
                        <p>Cofundadora, Qu&iacute;mica-Farmac&eacute;utica</p>
                    </div>

                </div>

            </div>            
        </section>
    )
}

export {QuienesSomos};