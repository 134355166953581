import React from "react";
import {  NavLink } from "react-router-dom";

import "./Header.css";
import logotipo from "../../assets/images/logotipo.svg";

function Header() {
  const [classNameSBar, setClassNameBar] = React.useState("");
  const [classNameHeader, setClassNameHeader] = React.useState("");

  React.useEffect(() => {

    window.addEventListener("scroll", (e)=>{
        
        setClassNameHeader(window.scrollY>0?'header-scroll':'')
        
    });

  }, []);

  function handleBars(e) {
    setClassNameBar(classNameSBar ? "" : "showNavBar");
  }

  return (
    <header className={"header container "+classNameHeader}>
      <NavLink to="/" className="logo">
      </NavLink>

      <div
        id="fa-bars"
        onClick={() => handleBars()}
        className="fas fa-bars"
      ></div>

      {/* TODO: Crear link */}
      <nav className={"navbar " + classNameSBar}>
        <a href="/#quienes-somos"  >Quienes somos</a>
        <a href="/#servicios" offset={10}>Servicios</a>
        <a href="/#contacto">Contacto</a>
      </nav>
    </header>
  );
}

export { Header };
