import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home";
import { Oferta } from "./pages/Oferta";
import { Header } from "./components/Header";
import CookieBanner from "./components/CookieBanner";
import TagManager from 'react-gtm-module';
import { NotFound } from "./pages/NotFound";
import { CookiesPolicy } from "./pages/CookiesPolicy";

const tagManagerArgs = {
    gtmId: 'GTM-N2PKXBN',
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/oferta" element={<Oferta />} />
          <Route path="/cookies-police" element={<CookiesPolicy />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <CookieBanner/>
      <a
          rel="noreferrer"
          href="https://wa.me/593939505116?text=Hola!%20Me%20interesa%20saber%20mas%20sobre%20la%20oferta."
          className="button-whatsapp"
          target="_blank"
        >
          <samp>
            <i className="fab fa-whatsapp"></i>
          </samp>
        </a>
    </div>
  );
}

export default App;
