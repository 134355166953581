import React from "react";
import { Footer } from "../components/Footer";
import footerbg from '../assets/images/footer-bg2.svg';

function NotFound (){
    return (
        <div>
            <main className="container main-oferta">
                 not NotFound
            </main>
            <Footer background={footerbg}/>
        </div>
    )
}

export {NotFound};