import React from "react";
import './Footer.css';
import logotipo from "../../assets/images/logo-footer.svg";

function Footer ({background}){
    return (
        <footer 
            className="footer-distributed container"
            style={{
                backgroundImage: `url(${background})`,
            }}
        >
           <div className="footer-main">
                <div className="footer-left">

                    <img className="logo-banner" src={logotipo} alt=""/>
    
                    <p className="footer-links">
                        <a href="/#home" className="link-1">Home</a>
    
                        <a href="/#quienes-somos">Quienes somos</a>
    
                        <a href="/#servicios">Servicios</a>
    
                        <a href="/#contacto">Contacto</a>
                    </p>
                    <div className="footer-social">
                        <a href="https://www.facebook.com/LaboratorioClinicodivinoJesus" className="link-1" rel="noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
                        <a href="https://www.instagram.com/laboratoriodivinojesus/" className="link-1" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                    </div>
                    
                </div>
    
                <div className="footer-right">
    
                    <div>
                        <i className="fa fa-map-marker"></i>
                        <p><span>Tungurahua 3006, entre Portete y Venezuela</span> Guayaquil, Ecuador</p>
                    </div>
    
                    <div>
                        <i className="fa fa-phone"></i>
                        <p>0939505116 - 0979253173 - 0963100751</p>
                    </div>
    
                    {/* <div>
                        <i className="fa fa-envelope"></i>
                        <p><a href="mailto:support@company.com">support@company.com</a></p>
                    </div> */}
    
                </div>
            </div>

            <p className="footer-copyright">Copyright © 2022 || Design By Zevallos Guillermo.</p>
        </footer>
    )
}

export {Footer};