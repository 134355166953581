import React from "react";

import './HomeBanner.css'
import logoBanner from '../../assets/images/logo-banner.svg';
import forma from '../../assets/images/forma1.svg';
import BannerSvg from "./BannerImage";

function HomeBanner(){
    return (
        <section className="home" id="home">
            <img id="formaBanner1" src={forma} alt="" /> 
            <img id="formaBanner2" src={forma} alt="" />
            <div>
                <img className="logo-banner" src={logoBanner} alt="" />
                <h1>Laboratorio Cl&iacute;nico Divino Jes&uacute;s</h1>
                <p>Servicio personalizado de alta <span>calidad</span> y <span>confiabilidad</span>. Contamos con
                    precios muy especiales para pacientes y para m&eacute;dicos. Equipos autom&aacute;ticos y semiautom&aacute;ticos, sistema
                    de datos en l&iacute;nea.</p>
            </div>
            {/* <img className="img-banner" src={banner} alt="banner"/> */}
            <div className="svg-container">
                <BannerSvg/>
                <div className="back-container">
                    <img src={forma} alt="" /> 
                    {/* <div className="ellipse red"></div>
                    <div className="ellipse purple"></div> */}
                    
                </div>
            </div>
            
            
        </section>
    )
}

export {HomeBanner};