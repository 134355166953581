import React from "react";
import './Servicios.css'

import cardbg from '../../assets/images/card-bg.jpg';
import cdoctores from '../../assets/images/cdoctores.svg';
import cpacientes from '../../assets/images/cpacientes.svg';
import serviciosbg from '../../assets/images/servicios-bg.svg';
import forma from '../../assets/images/forma1.svg';

function Servicios (){
    let styleCard= {
        backgroundImage: `url(${cardbg})`,
    }
    return (
        <section 
            id="servicios" 
            className="servicios container"
            style={{
                backgroundImage: `url(${serviciosbg})`,
            }}
        >
            <img id="formaServicio1" src={forma} alt=""/>
            <img id="formaServicio2" src={forma} alt=""/>
            <div className="texto-servicios">
                <h2>Servicios</h2>
                <p>"Divino Jes&uacute;s" Como Laboratorio de An&aacute;lisis Cl&iacute;nico, somos una empresa conformada por Qu&iacute;micos Farmac&eacute;uticos altamente capacitados en el &aacute;rea. Les brindamos a nuestros pacientes resultados seguros y r&aacute;pidos. Contamos con equipos de calidad.</p>
            </div>

            <div className="nuestros-servicios">

                <div className="servicio" style={styleCard}>
                    <img src={cdoctores} alt="convenio con doctores y empresas"/>
                    <p>Convenio con doctores y empresas</p>
                </div>
                <div className="servicio" style={styleCard}>
                    <img src={cpacientes} alt="consultas con pacientes"/>
                    <p>Consultas con pacientes</p>
                </div>
            </div>
        </section>
    )
}

export {Servicios};