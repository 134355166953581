import React from "react";
import footerbg from '../assets/images/footer-bg.svg';
import { Contacto } from "../components/Contacto";
import { Footer } from "../components/Footer";
import { HomeBanner } from "../components/HomeBanner";
import { QuienesSomos } from "../components/QuienesSomos";
import { Servicios } from "../components/Servicios";

function Home (){
    return (
        <>
            <main>
                <HomeBanner/>
                <QuienesSomos/>
                <Servicios/>
                <Contacto/>
            </main>
            <Footer background={footerbg}/>
        </>
    )
}

export {Home};