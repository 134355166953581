import React from "react";
import CookieConsent from "react-cookie-consent";

import TagManager from "react-gtm-module";

const CookieBanner = () => {
  const [tagCookies, setTagCookies] = React.useState(false);
  

    React.useEffect(()=>{
        let cookies = localStorage.getItem('cookies-aceptadas');
        if(cookies){
            TagManager.dataLayer(JSON.parse(cookies));
        }else{
            setTagCookies('false');
        }
    },[tagCookies])


  return (
    <div>
      <CookieConsent
        location="bottom"
        hideOnAccept={true}
        buttonText="Aceptar y Cerrar"
        buttonClasses="btn btn-primary"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "var(--dark-color2)",
          padding: "0 15%",
          fontSize: "1.5rem",
          boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)",
        }}
        buttonStyle={{
          height: "45px",
          width: "100%",
          background: "var(--purple-color)",
          fontWeight: "bold",
          color: "#fff",
          border: "none",
          borderRadius: "10px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        expires={150}
        onAccept={() => {
          localStorage.setItem('cookies-aceptadas',JSON.stringify({
            dataLayer: {
                'event': 'cookies-aceptadas'
            },
          }))
          setTagCookies(true);
        }}
      >
        Este sitio web utiliza cookies para mejora tu experiencia.{' '}Para mas información visite <a href="/cookies-police" style={{color: "var(--purple-color)",}}>Políticas de cookies</a>
      </CookieConsent>
    </div>
  );
};

export default CookieBanner;
