import React from "react";
import { Footer } from "../components/Footer";
import footerbg from '../assets/images/footer-bg2.svg';
import { LandingOferta } from "../components/LandingOferta";

function Oferta (){
    return (
        <div>
            <main className="container main-oferta">
                 <LandingOferta/>
            </main>
            <Footer background={footerbg}/>
        </div>
    )
}

export {Oferta};