import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contacto.css";
import contactobg from "../../assets/images/contacto-bg.svg";

function Contacto() {
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    
    emailjs
      .sendForm(
        "service_6vj0o3m",
        "template_p55z7xc",
        form.current,
        "HzISKj8pQlTkRxmFK"
      )
      .then(
        (result) => {
          setLoading(false);
          e.target.classList.add('submit');
          cleanForm(e.target);
          setTimeout(() => {
            
            e.target.classList.remove('submit');
          }, 3000);
        },
        (error) => {
          setLoading(false);
          console.log(error.text);
          e.target.classList.add('submit-error');
          cleanForm(e.target)
          setTimeout(() => {
            
            e.target.classList.remove('submit-error');
          }, 3000);
        }
      );
  };

  let iframeSource = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.861674037572!2d-79.9043423852405!3d-2.2059089379428074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6fdd09666ef3%3A0x4a54048bdcfe13f6!2sLaboratorio%20Clinico%20Divino%20Jesus!5e0!3m2!1ses!2sec!4v1655698299826!5m2!1ses!2sec"
    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"></iframe>`;

  return (
    <section
      id="contacto"
      className="contacto container"
      style={{
        backgroundImage: `url(${contactobg})`,
      }}
    >
      <h2>¿D&oacute;nde nos ubicamos?</h2>
      <div className="informacion">
        <Iframe iframe={iframeSource} />,
        <form
          ref={form}
          onSubmit={sendEmail}
          className="pageclip-form form"
        >
          <input type="text" placeholder="Nombre..."  name="Nombre" required />
          <input type="email" placeholder="E-mail..." name="Email" required />
          <textarea
            name="cotizacion"
            id=""
            cols="30"
            rows="10"
            placeholder="Cotiza su examen..."
            required
          />
          {
            loading?
                <ButtonLoading/>
            :
                <button className="button pageclip-form__submit">Enviar</button>
          }
        </form>
      </div>

    </section>
  );
}

function Iframe(props) {
  return (
    <>
      <div
        className="iframe"
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    </>
  );
}

function ButtonLoading() {
  return (
    <div className="button-Loading">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

function cleanForm(formTarget){
  let children = formTarget.children;
  for(let i =0; i < 3 ; i++){
    children[i].value = '';
  }
}

export { Contacto };
