import React from "react";
import bannerOferta from '../../assets/images/banner-oferta.svg';
import './LandingOferta.css';

function LandingOferta() {
  return (
    <>
      <div className="imagen-container">
        <img src={bannerOferta} alt="Imagen de oferta" />
      </div>
      <div className="form-container">
        <h2>Examen de Rutina</h2>
        <h3>Oferta Especial</h3>
        <p>La mejor manera de invertir tu dinero es hacerlo en vos mismo(a).</p>
        <p>
          <samp>¡YO CUIDO MI SALUD!</samp>
        </p>
        <a
          rel="noreferrer"
          href="https://wa.me/593939505116?text=Hola!%20Me%20interesa%20saber%20mas%20sobre%20la%20oferta."
          className="button-whatsapp"
          target="_blank"
        >
          <samp>
            Pedir información por WhatsApp <i className="fab fa-whatsapp"></i>
          </samp>
        </a>
      </div>
    </>
  );
}

export { LandingOferta };
